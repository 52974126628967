/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { 
  RiArrowLeftSLine, 
  RiCheckboxCircleLine,
  RiCloseCircleLine,
  RiStarFill,
  RiStarLine,
  RiInformationLine,
  RiPriceTag3Line,
  RiUserSmileLine,
  RiComputerLine,
  RiCustomerService2Line,
  RiExternalLinkLine,
  RiUserHeartLine,
  RiQuestionLine,
  RiSettings3Line,
  RiUserLine,
  RiPlugLine,
  RiBarChart2Line,
  RiPaintBrushLine,
  RiLineChartLine,
  RiQuestionAnswerLine
} from "react-icons/ri"

const EmployeeEngagementPage = () => {
  // Employee Engagement Software comparison data
  const engagementSoftwareComparison = [
    {
      name: "Culture Amp",
      website: "https://www.cultureamp.com",
      priceRange: "$$$",
      pricingModel: "Per employee/year",
      userRating: 4.5,
      userReviews: 780,
      usability: 4.4,
      mobileFriendly: true,
      pulseSurveys: true,
      companySize: "Mid-size to Enterprise",
      keyFeatures: [
        "Science-backed survey templates",
        "Advanced analytics dashboard",
        "Customizable pulse surveys",
        "Integrated performance management",
        "Robust action planning tools"
      ],
      limitations: [
        "Higher price point",
        "Can be complex for small teams",
        "Some advanced features require higher tiers",
        "Steep learning curve for analytics"
      ],
      bestFor: "Data-driven organizations seeking comprehensive employee insights"
    },
    {
      name: "Lattice Engagement",
      website: "https://lattice.com/engagement",
      priceRange: "$$",
      pricingModel: "Per user/month (annual billing)",
      userRating: 4.7,
      userReviews: 650,
      usability: 4.6,
      mobileFriendly: true,
      pulseSurveys: true,
      companySize: "Small to Mid-size",
      keyFeatures: [
        "Integrated with performance management",
        "Anonymous feedback channels",
        "Customizable survey templates",
        "Action planning tools",
        "HRIS integration capabilities"
      ],
      limitations: [
        "Price increases with added modules",
        "Some reporting limitations in basic tier",
        "Limited enterprise customization",
        "Basic survey logic options"
      ],
      bestFor: "Growing organizations wanting to combine engagement with performance"
    },
    {
      name: "15Five",
      website: "https://www.15five.com",
      priceRange: "$$",
      pricingModel: "Per user/month (annual billing)",
      userRating: 4.6,
      userReviews: 720,
      usability: 4.8,
      mobileFriendly: true,
      pulseSurveys: true,
      companySize: "Small to Mid-size",
      keyFeatures: [
        "Weekly check-ins",
        "OKR tracking",
        "Pulse surveys",
        "1-on-1 meeting tools",
        "Recognition system"
      ],
      limitations: [
        "Limited advanced analytics",
        "Fewer enterprise features",
        "Basic customization options",
        "Limited integration options in basic plan"
      ],
      bestFor: "Companies seeking ongoing employee feedback through check-ins and surveys"
    },
    {
      name: "Officevibe",
      website: "https://officevibe.com",
      priceRange: "$$",
      pricingModel: "Per user/month",
      userRating: 4.5,
      userReviews: 540,
      usability: 4.7,
      mobileFriendly: true,
      pulseSurveys: true,
      companySize: "Small to Mid-size",
      keyFeatures: [
        "Automated pulse surveys",
        "Anonymous feedback channel",
        "Recognition hub",
        "Team health metrics",
        "Intuitive manager dashboard"
      ],
      limitations: [
        "Limited customization options",
        "Basic reporting capabilities",
        "Fewer enterprise features",
        "Limited survey question types"
      ],
      bestFor: "Teams wanting simple, automated engagement measurement with minimal administration"
    },
    {
      name: "Peakon (Workday)",
      website: "https://www.workday.com/peakon",
      priceRange: "$$$",
      pricingModel: "Per employee/month",
      userRating: 4.4,
      userReviews: 610,
      usability: 4.3,
      mobileFriendly: true,
      pulseSurveys: true,
      companySize: "Mid-size to Enterprise",
      keyFeatures: [
        "Intelligent benchmarking",
        "Predictive analytics",
        "Continuous listening",
        "Sentiment analysis",
        "Multiple language support"
      ],
      limitations: [
        "Higher price point",
        "Complex implementation",
        "Steeper learning curve",
        "Some features only in enterprise tier"
      ],
      bestFor: "Large organizations needing sophisticated analytics and benchmarking"
    },
    {
      name: "Qualtrics EmployeeXM",
      website: "https://www.qualtrics.com/employee-experience",
      priceRange: "$$$",
      pricingModel: "Custom enterprise pricing",
      userRating: 4.3,
      userReviews: 680,
      usability: 4.1,
      mobileFriendly: true,
      pulseSurveys: true,
      companySize: "Mid-size to Enterprise",
      keyFeatures: [
        "Advanced text and sentiment analysis",
        "Comprehensive experience metrics",
        "Extensive survey capabilities",
        "Robust statistical analysis",
        "Enterprise-grade security"
      ],
      limitations: [
        "Significant cost investment",
        "Complex implementation",
        "Steep learning curve",
        "Requires dedicated administration"
      ],
      bestFor: "Large enterprises requiring sophisticated research and analytics capabilities"
    },
    {
      name: "Bonusly",
      website: "https://bonus.ly",
      priceRange: "$",
      pricingModel: "Per user/month (tiered plans)",
      userRating: 4.8,
      userReviews: 490,
      usability: 4.9,
      mobileFriendly: true,
      pulseSurveys: false,
      companySize: "Small to Mid-size",
      keyFeatures: [
        "Peer-to-peer recognition",
        "Custom company values",
        "Recognition analytics",
        "Reward marketplace",
        "Extensive integrations"
      ],
      limitations: [
        "Limited survey capabilities",
        "Focus on recognition vs. comprehensive engagement",
        "Basic feedback mechanisms",
        "Limited advanced analytics"
      ],
      bestFor: "Companies prioritizing recognition and rewards as engagement drivers"
    },
    {
      name: "Glint (LinkedIn)",
      website: "https://www.linkedin.com/business/talent/glint",
      priceRange: "$$$",
      pricingModel: "Per employee/year",
      userRating: 4.4,
      userReviews: 530,
      usability: 4.5,
      mobileFriendly: true,
      pulseSurveys: true,
      companySize: "Mid-size to Enterprise",
      keyFeatures: [
        "AI-driven insights",
        "Integrated action planning",
        "LinkedIn integration",
        "Real-time employee feedback",
        "Engagement trend analysis"
      ],
      limitations: [
        "Higher price point",
        "Complex implementation",
        "Better suited for larger organizations",
        "Some advanced features require higher tiers"
      ],
      bestFor: "Organizations using LinkedIn's talent ecosystem seeking advanced engagement analytics"
    },
    {
      name: "TINYpulse",
      website: "https://www.tinypulse.com",
      priceRange: "$$",
      pricingModel: "Per user/month",
      userRating: 4.5,
      userReviews: 480,
      usability: 4.6,
      mobileFriendly: true,
      pulseSurveys: true,
      companySize: "Small to Mid-size",
      keyFeatures: [
        "Anonymous pulse surveys",
        "Virtual suggestion box",
        "Peer recognition system",
        "Cheers for Peers",
        "Performance coaching tools"
      ],
      limitations: [
        "Limited advanced analytics",
        "Basic customization options",
        "Fewer enterprise features",
        "Limited integration capabilities"
      ],
      bestFor: "Small to mid-sized organizations wanting simple, effective feedback mechanisms"
    },
    {
      name: "Energage",
      website: "https://energage.com",
      priceRange: "$$",
      pricingModel: "Annual subscription with tiered packages",
      userRating: 4.2,
      userReviews: 410,
      usability: 4.3,
      mobileFriendly: true,
      pulseSurveys: true,
      companySize: "Small to Mid-size",
      keyFeatures: [
        "Workplace survey technology",
        "Industry benchmarking",
        "Top Workplaces recognition program",
        "Action planning tools",
        "Employee lifecycle surveys"
      ],
      limitations: [
        "Less known than competitors",
        "Limited international presence",
        "Fewer integration options",
        "Basic mobile experience"
      ],
      bestFor: "Organizations interested in workplace recognition alongside engagement measurement"
    }
  ];

  // Helper function to render star ratings
  const renderStars = (rating) => {
    const stars = [];
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 >= 0.3;
    
    for (let i = 1; i <= 5; i++) {
      if (i <= fullStars) {
        stars.push(<RiStarFill key={i} sx={{ color: 'primary' }} />);
      } else if (i === fullStars + 1 && hasHalfStar) {
        stars.push(<RiStarFill key={i} sx={{ color: 'primary', opacity: 0.5 }} />);
      } else {
        stars.push(<RiStarLine key={i} sx={{ color: 'primary' }} />);
      }
    }
    
    return <div sx={{ display: 'flex', alignItems: 'center' }}>{stars} <span sx={{ ml: 1 }}>({rating}/5)</span></div>;
  };

  return (
    <Layout>
      <SEO 
        title="Top 10 Employee Engagement Software Platforms Compared | HR Software Guide"
        description="Compare the leading employee engagement software solutions for 2025. Detailed analysis of features, pricing, usability, and user feedback to find the best platform for your organization."
        keywords={[
          "employee engagement software",
          "engagement platforms comparison",
          "pulse survey tools",
          "best engagement software",
          "employee feedback systems",
          "employee experience platforms",
          "staff engagement solutions"
        ]}
      />
      
      <div sx={{
        bg: 'primaryMuted',
        pt: [4, 5],
        pb: [4, 5]
      }}>
        <div sx={{
          maxWidth: '1200px',
          mx: 'auto',
          px: 3
        }}>
          <div sx={{
            display: 'flex',
            alignItems: 'center',
            mb: 4
          }}>
            <Link to="/software" sx={{
              display: 'flex',
              alignItems: 'center',
              color: 'primary',
              textDecoration: 'none',
              fontSize: '1rem',
              fontWeight: 500
            }}>
              <RiArrowLeftSLine sx={{ mr: 1 }} /> Back to HR Software
            </Link>
          </div>
          
          <div sx={{
            textAlign: 'center',
            mb: 2,
            fontSize: '0.95rem',
            color: 'text',
            opacity: 0.8
          }}>
            Last Updated: March 2025
          </div>
          
          <h1 sx={{
            fontSize: ['1.8rem', '2.3rem'],
            fontWeight: 700,
            textAlign: 'center',
            mb: 3,
            color: 'primary'
          }}>
            Top 10 Employee Engagement Software Compared
          </h1>
          
          <p sx={{
            fontSize: '1.1rem',
            textAlign: 'center',
            maxWidth: '800px',
            mx: 'auto',
            mb: 5,
            lineHeight: 1.5,
            color: 'text'
          }}>
            An in-depth comparison of the leading employee engagement platforms to help you find the right
            solution for measuring, analyzing, and improving employee engagement in your organization.
          </p>
          
          {/* Comparison criteria explanation */}
          <div sx={{
            bg: 'white',
            p: 3,
            borderRadius: '8px',
            boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
            mb: 5
          }}>
            <h2 sx={{
              fontSize: '1.3rem',
              fontWeight: 600,
              mb: 3,
              display: 'flex',
              alignItems: 'center'
            }}>
              <RiInformationLine sx={{ mr: 2, color: 'primary' }} /> Comparison Criteria
            </h2>
            
            <div sx={{
              display: 'grid',
              gridTemplateColumns: ['1fr', '1fr 1fr', '1fr 1fr 1fr 1fr'],
              gap: 3,
              mb: 3
            }}>
              <div>
                <h3 sx={{ fontSize: '1rem', fontWeight: 600, mb: 1, display: 'flex', alignItems: 'center' }}>
                  <RiPriceTag3Line sx={{ mr: 1, color: 'primary' }} /> Pricing
                </h3>
                <p sx={{ fontSize: '0.9rem', opacity: 0.8 }}>
                  $ = Budget (Under $5/user/mo)<br />
                  $$ = Mid-range ($5-15/user/mo)<br />
                  $$$ = Premium ($15-30/user/mo)<br />
                  $$$$ = Enterprise (Custom pricing)
                </p>
              </div>
              
              <div>
                <h3 sx={{ fontSize: '1rem', fontWeight: 600, mb: 1, display: 'flex', alignItems: 'center' }}>
                  <RiUserSmileLine sx={{ mr: 1, color: 'primary' }} /> User Rating
                </h3>
                <p sx={{ fontSize: '0.9rem', opacity: 0.8 }}>
                  Based on verified reviews across G2, Capterra, and HR software directories. Rating out of 5.
                </p>
              </div>
              
              <div>
                <h3 sx={{ fontSize: '1rem', fontWeight: 600, mb: 1, display: 'flex', alignItems: 'center' }}>
                  <RiComputerLine sx={{ mr: 1, color: 'primary' }} /> Usability
                </h3>
                <p sx={{ fontSize: '0.9rem', opacity: 0.8 }}>
                  Ease of use rating based on user interface, survey setup, reporting tools, and administrative complexity.
                </p>
              </div>
              
              <div>
                <h3 sx={{ fontSize: '1rem', fontWeight: 600, mb: 1, display: 'flex', alignItems: 'center' }}>
                  <RiCustomerService2Line sx={{ mr: 1, color: 'primary' }} /> Company Size
                </h3>
                <p sx={{ fontSize: '0.9rem', opacity: 0.8 }}>
                  The ideal organization size where the platform performs best based on features, scalability, and pricing.
                </p>
              </div>
            </div>
          </div>
          
          {/* Engagement software comparison cards will go here */}
          
          <div sx={{
            display: 'grid',
            gridTemplateColumns: ['1fr', null, '1fr 1fr'],
            gap: 4,
            mb: 5
          }}>
            {engagementSoftwareComparison.map((software, index) => (
              <div key={`software-${software.name}`} sx={{
                bg: 'white',
                borderRadius: '10px',
                overflow: 'hidden',
                boxShadow: '0 3px 10px rgba(0,0,0,0.08)',
                display: 'flex',
                flexDirection: 'column',
                transition: 'transform 0.2s, box-shadow 0.2s',
                ':hover': {
                  transform: 'translateY(-3px)',
                  boxShadow: '0 8px 20px rgba(0,0,0,0.12)'
                }
              }}>
                <div sx={{
                  p: 3,
                  borderBottom: '1px solid',
                  borderColor: 'border'
                }}>
                  <div sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                    mb: 2
                  }}>
                    <h2 sx={{
                      fontSize: ['1.2rem', '1.3rem'],
                      fontWeight: 600,
                      mb: 1,
                      color: 'primary'
                    }}>
                      {software.name}
                    </h2>
                    <div sx={{
                      bg: 'primaryMuted',
                      px: 2,
                      py: 1,
                      borderRadius: '20px',
                      fontSize: '0.8rem',
                      fontWeight: 600,
                      color: 'primary'
                    }}>
                      {software.priceRange}
                    </div>
                  </div>
                  
                  <div sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mb: 2
                  }}>
                    <div sx={{ display: 'flex', color: 'amber' }}>
                      {renderStars(software.userRating)}
                    </div>
                    <div sx={{ ml: 2, fontSize: '0.9rem', opacity: 0.8 }}>
                      {software.userRating} ({software.userReviews} reviews)
                    </div>
                  </div>
                  
                  <div sx={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    gap: 3,
                    mb: 3
                  }}>
                    <div>
                      <div sx={{ fontSize: '0.85rem', fontWeight: 500, mb: 1 }}>Pricing Model</div>
                      <div sx={{ fontSize: '0.9rem' }}>{software.pricingModel}</div>
                    </div>
                    <div>
                      <div sx={{ fontSize: '0.85rem', fontWeight: 500, mb: 1 }}>Usability</div>
                      <div sx={{ fontSize: '0.9rem', display: 'flex', alignItems: 'center' }}>
                        {renderStars(software.usability)}
                        <span sx={{ ml: 1 }}>{software.usability}/5</span>
                      </div>
                    </div>
                    <div>
                      <div sx={{ fontSize: '0.85rem', fontWeight: 500, mb: 1 }}>Mobile Friendly</div>
                      <div sx={{ fontSize: '0.9rem' }}>{software.mobileFriendly ? 'Yes' : 'No'}</div>
                    </div>
                    <div>
                      <div sx={{ fontSize: '0.85rem', fontWeight: 500, mb: 1 }}>Pulse Surveys</div>
                      <div sx={{ fontSize: '0.9rem' }}>{software.pulseSurveys ? 'Yes' : 'No'}</div>
                    </div>
                    <div sx={{ gridColumn: '1 / span 2' }}>
                      <div sx={{ fontSize: '0.85rem', fontWeight: 500, mb: 1 }}>Company Size</div>
                      <div sx={{ fontSize: '0.9rem' }}>{software.companySize}</div>
                    </div>
                  </div>
                  
                  <a href={software.website} target="_blank" rel="noopener noreferrer" sx={{
                    display: 'block',
                    width: '100%',
                    py: 2,
                    textAlign: 'center',
                    bg: 'primary',
                    color: 'white',
                    fontWeight: 500,
                    borderRadius: '5px',
                    textDecoration: 'none',
                    transition: 'transform 0.2s ease, box-shadow 0.2s ease, background-color 0.2s ease',
                    ':hover': {
                      bg: 'primaryDark',
                      color: 'white',
                      transform: 'translateY(-2px)',
                      boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
                    }
                  }}>
                    Visit Website <RiExternalLinkLine sx={{ ml: 1, verticalAlign: 'middle' }} />
                  </a>
                </div>
                
                <div sx={{ p: 3, bg: 'background', flex: 1 }}>
                  <div sx={{ mb: 3 }}>
                    <h3 sx={{ fontSize: '1rem', fontWeight: 600, mb: 2, display: 'flex', alignItems: 'center' }}>
                      <RiCheckboxCircleLine sx={{ mr: 1, color: 'success' }} /> Key Features
                    </h3>
                    <ul sx={{ pl: 3, mb: 0 }}>
                      {software.keyFeatures.map((feature, i) => (
                        <li key={`feature-${software.name}-${i}`} sx={{ mb: 1, fontSize: '0.9rem' }}>{feature}</li>
                      ))}
                    </ul>
                  </div>
                  
                  <div sx={{ mb: 3 }}>
                    <h3 sx={{ fontSize: '1rem', fontWeight: 600, mb: 2, display: 'flex', alignItems: 'center' }}>
                      <RiCloseCircleLine sx={{ mr: 1, color: 'danger' }} /> Limitations
                    </h3>
                    <ul sx={{ pl: 3, mb: 0 }}>
                      {software.limitations.map((limitation, i) => (
                        <li key={`limitation-${software.name}-${i}`} sx={{ mb: 1, fontSize: '0.9rem' }}>{limitation}</li>
                      ))}
                    </ul>
                  </div>
                  
                  <div>
                    <h3 sx={{ fontSize: '1rem', fontWeight: 600, mb: 2, display: 'flex', alignItems: 'center' }}>
                      <RiUserHeartLine sx={{ mr: 1, color: 'primary' }} /> Best For
                    </h3>
                    <p sx={{ mb: 0, fontSize: '0.9rem' }}>{software.bestFor}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          
          {/* Engagement Software Selection Guide */}
          <div sx={{
            bg: 'white',
            borderRadius: '10px',
            boxShadow: '0 3px 10px rgba(0,0,0,0.08)',
            p: 4,
            mb: 5
          }}>
            <h2 sx={{
              fontSize: ['1.4rem', '1.5rem'],
              fontWeight: 600,
              mb: 4,
              color: 'primary',
              display: 'flex',
              alignItems: 'center'
            }}>
              <RiQuestionLine sx={{ mr: 2 }} /> Engagement Software Selection Guide
            </h2>
            
            <div sx={{
              display: 'grid',
              gridTemplateColumns: ['1fr', null, '1fr 1fr'],
              gap: 4
            }}>
              <div>
                <h3 sx={{ fontSize: '1.1rem', fontWeight: 600, mb: 2, display: 'flex', alignItems: 'center' }}>
                  <RiSettings3Line sx={{ mr: 1, color: 'primary' }} /> Define Your Engagement Strategy
                </h3>
                <p sx={{ fontSize: '0.95rem', lineHeight: 1.6 }}>
                  Before selecting a platform, clarify your engagement measurement goals. Are you focusing on 
                  pulse surveys, comprehensive annual surveys, continuous feedback, or a mix? Different platforms 
                  excel in different approaches to measuring and improving engagement.
                </p>
              </div>
              
              <div>
                <h3 sx={{ fontSize: '1.1rem', fontWeight: 600, mb: 2, display: 'flex', alignItems: 'center' }}>
                  <RiUserLine sx={{ mr: 1, color: 'primary' }} /> Consider Employee Experience
                </h3>
                <p sx={{ fontSize: '0.95rem', lineHeight: 1.6 }}>
                  The best platforms balance thorough data collection with a streamlined employee experience. 
                  Evaluate how simple the survey process is, whether it's mobile-friendly, and if it respects 
                  employee time. Low completion rates will undermine even the best engagement program.
                </p>
              </div>
              
              <div>
                <h3 sx={{ fontSize: '1.1rem', fontWeight: 600, mb: 2, display: 'flex', alignItems: 'center' }}>
                  <RiPlugLine sx={{ mr: 1, color: 'primary' }} /> Integration Capabilities
                </h3>
                <p sx={{ fontSize: '0.95rem', lineHeight: 1.6 }}>
                  Look for platforms that connect with your existing HR tech stack. Integration with your HRIS, 
                  performance management systems, or communication tools creates a seamless workflow and enhances 
                  data insights through cross-platform analytics.
                </p>
              </div>
              
              <div>
                <h3 sx={{ fontSize: '1.1rem', fontWeight: 600, mb: 2, display: 'flex', alignItems: 'center' }}>
                  <RiBarChart2Line sx={{ mr: 1, color: 'primary' }} /> Analytics and Insights
                </h3>
                <p sx={{ fontSize: '0.95rem', lineHeight: 1.6 }}>
                  Evaluate the depth and accessibility of analytics. Look for benchmarking capabilities, 
                  trend analysis, demographic filtering, and actionable recommendations. The best platforms 
                  translate raw survey data into clear insights that drive meaningful action.
                </p>
              </div>
              
              <div>
                <h3 sx={{ fontSize: '1.1rem', fontWeight: 600, mb: 2, display: 'flex', alignItems: 'center' }}>
                  <RiPaintBrushLine sx={{ mr: 1, color: 'primary' }} /> Action Planning Tools
                </h3>
                <p sx={{ fontSize: '0.95rem', lineHeight: 1.6 }}>
                  Measurement is only the beginning. Evaluate platforms on their ability to facilitate action planning, 
                  assign responsibilities, track progress, and close the feedback loop with employees. The best systems 
                  drive organizational change, not just measurement.
                </p>
              </div>
              
              <div>
                <h3 sx={{ fontSize: '1.1rem', fontWeight: 600, mb: 2, display: 'flex', alignItems: 'center' }}>
                  <RiLineChartLine sx={{ mr: 1, color: 'primary' }} /> Scalability & Admin Support
                </h3>
                <p sx={{ fontSize: '0.95rem', lineHeight: 1.6 }}>
                  Consider your organization's growth trajectory and international presence. Evaluate platforms 
                  on multi-language support, scalable pricing, ease of administration, and vendor support services. 
                  Implementation complexity varies significantly across platforms.
                </p>
              </div>
            </div>
          </div>
          
          {/* Engagement Trends */}
          <div sx={{
            bg: 'white',
            borderRadius: '10px',
            boxShadow: '0 3px 10px rgba(0,0,0,0.08)',
            p: 4,
            mb: 5
          }}>
            <h2 sx={{
              fontSize: ['1.4rem', '1.5rem'],
              fontWeight: 600,
              mb: 4,
              color: 'primary',
              display: 'flex',
              alignItems: 'center'
            }}>
              <RiLineChartLine sx={{ mr: 2 }} /> Employee Engagement Trends
            </h2>
            
            <div sx={{
              display: 'grid',
              gridTemplateColumns: ['1fr', null, '1fr 1fr', '1fr 1fr 1fr'],
              gap: 4
            }}>
              <div sx={{
                bg: 'background',
                p: 3,
                borderRadius: '8px'
              }}>
                <h3 sx={{ fontSize: '1.1rem', fontWeight: 600, mb: 2 }}>
                  Continuous Listening
                </h3>
                <p sx={{ fontSize: '0.95rem', lineHeight: 1.6, mb: 0 }}>
                  Organizations are moving beyond annual surveys to implement ongoing feedback mechanisms, 
                  including pulse surveys, always-on feedback channels, and lifecycle-based measurement to 
                  capture real-time employee sentiment.
                </p>
              </div>
              
              <div sx={{
                bg: 'background',
                p: 3,
                borderRadius: '8px'
              }}>
                <h3 sx={{ fontSize: '1.1rem', fontWeight: 600, mb: 2 }}>
                  AI-Powered Analytics
                </h3>
                <p sx={{ fontSize: '0.95rem', lineHeight: 1.6, mb: 0 }}>
                  Advanced engagement platforms now leverage machine learning to identify correlations, predict 
                  turnover risks, analyze sentiment in open-ended responses, and generate personalized action 
                  recommendations for managers.
                </p>
              </div>
              
              <div sx={{
                bg: 'background',
                p: 3,
                borderRadius: '8px'
              }}>
                <h3 sx={{ fontSize: '1.1rem', fontWeight: 600, mb: 2 }}>
                  Integrated Employee Experience
                </h3>
                <p sx={{ fontSize: '0.95rem', lineHeight: 1.6, mb: 0 }}>
                  Engagement is increasingly viewed as part of a holistic employee experience strategy. 
                  Platforms now connect engagement data with performance, wellness, recognition, and development 
                  metrics for a comprehensive view.
                </p>
              </div>
              
              <div sx={{
                bg: 'background',
                p: 3,
                borderRadius: '8px'
              }}>
                <h3 sx={{ fontSize: '1.1rem', fontWeight: 600, mb: 2 }}>
                  Manager Enablement
                </h3>
                <p sx={{ fontSize: '0.95rem', lineHeight: 1.6, mb: 0 }}>
                  Leading platforms now focus on empowering managers with team-specific insights, guided 
                  action recommendations, coaching content, and accountability tools to drive engagement 
                  at the local level where impact is greatest.
                </p>
              </div>
              
              <div sx={{
                bg: 'background',
                p: 3,
                borderRadius: '8px'
              }}>
                <h3 sx={{ fontSize: '1.1rem', fontWeight: 600, mb: 2 }}>
                  Remote Work Insights
                </h3>
                <p sx={{ fontSize: '0.95rem', lineHeight: 1.6, mb: 0 }}>
                  With the rise of distributed work, engagement tools now incorporate specific metrics around 
                  remote work experience, virtual collaboration effectiveness, workspace ergonomics, and 
                  work-life balance measurement.
                </p>
              </div>
              
              <div sx={{
                bg: 'background',
                p: 3,
                borderRadius: '8px'
              }}>
                <h3 sx={{ fontSize: '1.1rem', fontWeight: 600, mb: 2 }}>
                  Diversity & Inclusion Focus
                </h3>
                <p sx={{ fontSize: '0.95rem', lineHeight: 1.6, mb: 0 }}>
                  Modern engagement platforms incorporate specialized metrics around belonging, psychological 
                  safety, and inclusive leadership, with demographic analysis tools that maintain anonymity 
                  while revealing experience gaps across groups.
                </p>
              </div>
            </div>
          </div>
          
          {/* FAQs */}
          <div sx={{
            mb: 5
          }}>
            <h2 sx={{
              fontSize: ['1.4rem', '1.5rem'],
              fontWeight: 600,
              mb: 4,
              color: 'primary',
              display: 'flex',
              alignItems: 'center'
            }}>
              <RiQuestionAnswerLine sx={{ mr: 2 }} /> Frequently Asked Questions
            </h2>
            
            <div sx={{
              bg: 'white',
              borderRadius: '10px',
              overflow: 'hidden',
              boxShadow: '0 3px 10px rgba(0,0,0,0.08)',
              mb: 3
            }}>
              <div sx={{ p: 3 }}>
                <h3 sx={{ fontSize: '1.1rem', fontWeight: 600, mb: 2 }}>
                  How often should we survey employees?
                </h3>
                <p sx={{ fontSize: '0.95rem', lineHeight: 1.6, mb: 0 }}>
                  Best practices now favor a balanced approach: comprehensive engagement surveys 1-2 times per year, 
                  supplemented with brief pulse surveys every 1-3 months. Critical transitional moments (onboarding, 
                  promotions, organizational changes) also warrant targeted measurement. The key is avoiding survey 
                  fatigue while maintaining a continuous feedback loop.
                </p>
              </div>
            </div>
            
            <div sx={{
              bg: 'white',
              borderRadius: '10px',
              overflow: 'hidden',
              boxShadow: '0 3px 10px rgba(0,0,0,0.08)',
              mb: 3
            }}>
              <div sx={{ p: 3 }}>
                <h3 sx={{ fontSize: '1.1rem', fontWeight: 600, mb: 2 }}>
                  How much do engagement platforms typically cost?
                </h3>
                <p sx={{ fontSize: '0.95rem', lineHeight: 1.6, mb: 0 }}>
                  Pricing varies significantly based on functionality, company size, and tier. Basic pulse survey 
                  tools may start around $3-5 per employee per month, while comprehensive engagement platforms 
                  typically range from $8-20 per employee per month. Enterprise solutions with advanced analytics 
                  and integration capabilities often use custom pricing and may cost $20-40 per employee per month.
                </p>
              </div>
            </div>
            
            <div sx={{
              bg: 'white',
              borderRadius: '10px',
              overflow: 'hidden',
              boxShadow: '0 3px 10px rgba(0,0,0,0.08)'
            }}>
              <div sx={{ p: 3 }}>
                <h3 sx={{ fontSize: '1.1rem', fontWeight: 600, mb: 2 }}>
                  How do we maintain anonymity while getting actionable insights?
                </h3>
                <p sx={{ fontSize: '0.95rem', lineHeight: 1.6, mb: 0 }}>
                  Leading engagement platforms use sophisticated anonymity thresholds (typically 5+ respondents) 
                  for demographic data analysis, aggregating responses to protect individual identities while still 
                  enabling meaningful segmentation. The best platforms clearly communicate anonymity protections to 
                  employees and offer optional identified feedback channels separate from anonymous surveys.
                </p>
              </div>
            </div>
          </div>
          
          {/* CTA */}
          <div sx={{
            textAlign: 'center',
            mb: 4
          }}>
            <h2 sx={{ fontSize: '1.4rem', fontWeight: 600, mb: 3 }}>
              Still researching HR software options?
            </h2>
            <p sx={{ mb: 4, maxWidth: '800px', mx: 'auto' }}>
              Explore our other in-depth comparison guides to find the right software solutions for your HR needs.
            </p>
            <div sx={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              gap: 3
            }}>
              <Link to="/software/hris" sx={{
                py: 2,
                px: 3,
                bg: 'primary',
                color: 'white',
                borderRadius: '5px',
                textDecoration: 'none',
                fontWeight: 500,
                transition: 'transform 0.2s ease, box-shadow 0.2s ease, background-color 0.2s ease',
                ':hover': {
                  bg: 'primaryDark',
                  color: 'white',
                  transform: 'translateY(-2px)',
                  boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
                }
              }}>
                HRIS Comparison
              </Link>
              <Link to="/software/applicant-tracking-system/" sx={{
                py: 2,
                px: 3,
                bg: 'primary',
                color: 'white',
                borderRadius: '5px',
                textDecoration: 'none',
                fontWeight: 500,
                transition: 'transform 0.2s ease, box-shadow 0.2s ease, background-color 0.2s ease',
                ':hover': {
                  bg: 'primaryDark',
                  color: 'white',
                  transform: 'translateY(-2px)',
                  boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
                }
              }}>
                ATS Comparison
              </Link>
              <Link to="/software/performance-management" sx={{
                py: 2,
                px: 3,
                bg: 'primary',
                color: 'white',
                borderRadius: '5px',
                textDecoration: 'none',
                fontWeight: 500,
                transition: 'transform 0.2s ease, box-shadow 0.2s ease, background-color 0.2s ease',
                ':hover': {
                  bg: 'primaryDark',
                  color: 'white',
                  transform: 'translateY(-2px)',
                  boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
                }
              }}>
                Performance Management
              </Link>
              <Link to="/software/learning-management-system" sx={{
                py: 2,
                px: 3,
                bg: 'primary',
                color: 'white',
                borderRadius: '5px',
                textDecoration: 'none',
                fontWeight: 500,
                transition: 'transform 0.2s ease, box-shadow 0.2s ease, background-color 0.2s ease',
                ':hover': {
                  bg: 'primaryDark',
                  color: 'white',
                  transform: 'translateY(-2px)',
                  boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
                }
              }}>
                LMS Comparison
              </Link>
            </div>
          </div>
          
          {/* Get Expert Help CTA */}
          <div sx={{
            bg: 'primary',
            borderRadius: '12px',
            p: 4,
            textAlign: 'center',
            maxWidth: '800px',
            mx: 'auto',
            mb: 6
          }}>
            <h2 sx={{ 
              fontSize: ['1.5rem', '1.8rem'], 
              fontWeight: 700, 
              color: 'white',
              mb: 3 
            }}>
              Need Help Selecting the Right Employee Engagement Software?
            </h2>
            <p sx={{ 
              fontSize: ['1rem', '1.1rem'], 
              color: 'white',
              mb: 4,
              opacity: 0.9
            }}>
              Our HR technology experts can provide personalized recommendations based on your organization's 
              specific needs, culture, and existing tech stack.
            </p>
            <div sx={{
              display: 'flex',
              flexDirection: ['column', 'row'],
              justifyContent: 'center',
              gap: 3
            }}>
              <Link to="/contact" sx={{
                bg: 'white',
                color: 'primary',
                py: 2,
                px: 4,
                borderRadius: '40px',
                fontWeight: 600,
                textDecoration: 'none',
                fontSize: '1rem',
                display: 'inline-block',
                transition: 'transform 0.2s, box-shadow 0.2s',
                ':hover': {
                  transform: 'translateY(-3px)',
                  boxShadow: '0 6px 20px rgba(0,0,0,0.2)',
                  color: 'primary'
                }
              }}>
                Schedule a Free Consultation
              </Link>
              <Link to="/services/hr-software-selection" sx={{
                bg: 'transparent',
                color: 'white',
                py: 2,
                px: 4,
                borderRadius: '40px',
                fontWeight: 600,
                textDecoration: 'none',
                fontSize: '1rem',
                display: 'inline-block',
                border: '2px solid white',
                transition: 'transform 0.2s, box-shadow 0.2s, background-color 0.2s',
                ':hover': {
                  transform: 'translateY(-3px)',
                  boxShadow: '0 6px 20px rgba(0,0,0,0.2)',
                  bg: 'primaryDark',
                  color: 'white'
                }
              }}>
                Learn About Our Selection Process
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default EmployeeEngagementPage; 